<template>
    <v-data-table
        :headers="headers"
        :items="domains.data"
        :items-per-page="paginationParams.per_page"
        :page="paginationParams.page"
        :server-items-length="domains.total"
        :loading="loading"
        sort-by="id"
        class="border"
        @update:page="changePage"
        @update:items-per-page="changeItemsPerPage"
        :footer-props="footerProps"
        @update:sort-by="sortBy"
        @update:sort-desc="sortDesc"

    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-dialog v-model="dialog" max-width="800px">
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2" v-on="on">{{ trans('buttons.domain.newItem') }}</v-btn>
                    </template>
                    <domain-form v-if="dialogLoaded" :item-id="editedItem" v-on:saved="onSave" v-on:updated="onSave" v-on:close="close"></domain-form>
                </v-dialog>
            </v-toolbar>
            <v-dialog v-model="showItemDialog" max-width="800px">
                <domain-card v-if="showItemDialogLoaded" :id="showItemId" :key="showItemId" v-on:close="showItemDialogClose" v-on:click:outside="showItemDialogClose"></domain-card>
            </v-dialog>
            <v-container>
                <v-row>
                    <v-col style="min-width: 200px;">
                        <v-text-field
                                v-model="filters.id"
                                :label="trans('fields.common.id')"
                                clearable
                        ></v-text-field>
                    </v-col>
                    <v-col style="min-width: 200px;">
                        <v-text-field
                                v-model="filters.name"
                                :label="trans('fields.common.domain')"
                                clearable
                        ></v-text-field>
                    </v-col>
                    <v-col style="min-width: 200px;">
                        <user-autocomplete
                                v-model="filters.user_id"
                                :label="trans('fields.common.user')"
                                clearable
                        ></user-autocomplete>
                    </v-col>
                    <v-col style="min-width: 200px;">
                        <v-text-field
                                v-model="filters.landing_id"
                                :label="trans('fields.common.landing')"
                                clearable
                        ></v-text-field>
                    </v-col>
                    <v-col style="min-width: 200px;">
                        <v-select
                                :items="options.domainStatuses"
                                :label="trans('fields.common.status')"
                                :multiple="true"
                                v-model="filters.status"
                                clearable
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-btn
                            class="ma-2"
                            color="primary"
                            outlined
                            @click="applyFilters"
                    >{{ trans('buttons.common.apply') }}</v-btn>
                    <v-btn
                            class="ma-2"
                            color="orange"
                            outlined
                            @click="clearFilters"
                    >{{ trans('buttons.common.clear') }}</v-btn>
                </v-row>
            </v-container>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="showItem(item)">mdi-eye</v-icon>
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:item.created_at="{ item }">
            {{ item.created_at | mysqlDate }}
        </template>
        <template v-slot:item.last_check="{ item }">
            {{ item.last_check | mysqlDate }}
        </template>
        <template v-slot:item.nextCheck="{ item }">
            <template v-if="item.nextCheck">
                {{ item.nextCheck | mysqlDate }}
            </template>
        </template>
        <template v-slot:item.user="{ item }">
            <template v-if="item.user">
                <user-link :user="item.user"></user-link>
            </template>
        </template>
        <template v-slot:item.landing="{ item }">
            <template v-if="item.landing">{{ item.landing.label }}</template>
        </template>
        <template v-slot:item.status="{ item }">
            <v-chip
                class="ma-2"
                :color="item.statusColor"
                text-color="white"
            >
                <v-avatar left>
                    <v-icon>{{ item.statusIcon }}</v-icon>
                </v-avatar>
                {{ item.statusLabel }}
            </v-chip>
            <p v-if="item.error_message">{{ trans('domain.error_messages.'+item.error_message) }}</p>
        </template>
    </v-data-table>
</template>

<script>
    import tableWithPagination from "@/mixins/tableWithPagination";
    import DomainCard from "./DomainCard";
    import tableFilters from "@/mixins/tableFilters";
    import UserAutocomplete from "@/components/admin/user/UserAutocomplete";
    import UserLink from "@/components/admin/user/UserLink";

    export default {
        name: "DomainTable",
        components: {
            UserLink,
            UserAutocomplete,
            DomainCard,
            DomainForm: () => import('@/components/admin/domain/DomainForm'),
        },
        mixins: [tableWithPagination, tableFilters],
        data() {
            return {
                dialog: false,
                dialogLoaded: false,
                showItemDialog: false,
                showItemDialogLoaded: false,
                domains: {},
                headers: [
                    { text: "id", sortable: false, value: "id" },
                    { text: this.trans("fields.common.domainName"), value: "name", sortable: false },
                    { text: this.trans("fields.common.dns_a"), value: "dns_a", sortable: false },
                    { text: this.trans("fields.common.dns_last_check"), value: "last_check", sortable: false },
                    { text: this.trans("fields.common.dns_next_check"), value: "nextCheck", sortable: false },
                    { text: this.trans("fields.common.dns_check_retries"), value: "check_retries", sortable: false },
                    { text: this.trans("fields.common.status"), value: "status", sortable: false },
                    { text: this.trans("fields.common.landing"), value: "landing", sortable: false },
                    { text: this.trans("fields.common.user"), value: "user", sortable: false },
                    { text: this.trans("fields.common.created_at_m"), value: "created_at", sortable: false },
                    { text: this.trans("fields.common.actions"), value: "actions", sortable: false },
                ],
                paginationParams: {
                    page: 1,
                    per_page: 10,
                },
                url: this.route('admin.domains.index'),

                filters: {
                    id: null,
                    name: null,
                    landing_id: null,
                    user_id: null,
                    status: null,
                },

                showItemId: null,
                editedItem: null,
            }
        },
        created() {
            this.editedItem = null;
            this.initialize();
            this.loadOptions({domainStatuses: true});
        },
        watch: {
            dialog(val) {
                if (val){
                    this.dialogLoaded = true
                } else {
                    this.close();
                }
            }
        },
        methods: {
            onSave(){
                this.close();
                this.initialize();
            },
            showItem(item) {
                console.log(item);
                this.showItemId = item.id;
                this.showItemDialog = true;
                this.showItemDialogLoaded = true;
            },
            editItem(item) {
                this.editedItem = item.id;
                this.dialog = true;
                this.dialogLoaded = true;
            },
            deleteItem(item) {
                confirm("Are you sure you want to delete this item?") &&
                console.log(['deleted', item]);
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = null;
                    this.dialogLoaded = false;
                }, 300);
            },
            showItemDialogClose() {
                this.showItemDialog = false;
                setTimeout(() => {
                    this.showItemId = null;
                    this.showItemDialogLoaded = false;
                }, 300);
            },


        },
        computed: {
            paginationItems: {
                get(){
                    return this.domains;
                },
                set(v){
                    this.domains = v;
                }
            },
        }
    }
</script>

<style scoped>

</style>
