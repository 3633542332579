<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row v-if="item">
                    <v-col cols="12" md="6">
                        <v-list two-line>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{ trans('fields.common.id') }}</v-list-item-subtitle>
                                    <v-list-item-title>{{ item.id }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{ trans('fields.common.user') }}</v-list-item-subtitle>
                                    <v-list-item-title>{{ item.user | user }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{ trans('fields.common.landing') }}</v-list-item-subtitle>
                                    <v-list-item-title>{{ item.landing ? item.landing.label : '---' }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{ trans('fields.common.domainName') }}</v-list-item-subtitle>
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{ trans('fields.common.domainNameIdn') }}</v-list-item-subtitle>
                                    <v-list-item-title>{{ item.idn }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-list two-line>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{ trans('fields.common.created_at_m') }}</v-list-item-subtitle>
                                    <v-list-item-title>{{ item.created_at | mysqlDate }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{ trans('fields.common.status') }}</v-list-item-subtitle>
                                    <v-list-item-title>
                                        <v-icon :color="`${item.statusColor} darken-2`">{{ item.statusIcon }}</v-icon>
                                        {{ item.statusLabel }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{ trans('fields.common.dns_last_check') }}</v-list-item-subtitle>
                                    <v-list-item-title>{{ item.last_check | mysqlDate }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{ trans('fields.common.dns_check_retries') }}</v-list-item-subtitle>
                                    <v-list-item-title>{{ item.check_retries }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="checkDns">{{ trans('buttons.domain.checkDns') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "DomainCard",
        props: {
            id: {
                type: [Number, String],
            },
        },
        data(){
            return {
                showRoute: 'admin.domains.show',
                dnsCheckRoute: 'admin.domains.dnsCheck',
                item: null,
                error: null,
                options: {

                },
            }
        },
        created() {
            this.load();
            this.loadOptions();
        },
        methods: {
            load(){
                axios.get(this.route(this.showRoute, this.id)).then( response => {
                    this.item = response.data;
                }).catch( err => {
                    this.error = err.response.data;
                });
            },
            checkDns(){
                axios.post(this.route(this.dnsCheckRoute, this.id)).then( () => {
                    this.load();
                }).catch( err => {
                    console.log(err);
                });
            },
            loadOptions(){
                // axios.get(this.route('admin.options'), {params: {
                //         freeLandings: this.editedItem.user_id,
                //     }}).then( response => {
                //     this.localOptions = response.data;
                // })
            },
        },
        computed: {
            formTitle() {
                return this.trans('pages.domain.showDialogTitle');
            },
        }
    }
</script>
